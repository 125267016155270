var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"LoginPwd"}},[_c('van-nav-bar',{attrs:{"title":_vm.$t('m_header.xgdlmm'),"left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"password","label":_vm.$t('m_changeWithdrawPassword.jmm'),"type":"password","placeholder":_vm.$t('m_modifyLoginPassword.qsrjmm'),"maxlength":"16","rules":[{ required: true, message: _vm.$t('m_modifyLoginPassword.qsrjmm') }]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('van-field',{attrs:{"name":"password","label":_vm.$t('m_modifyLoginPassword.xmm'),"type":"password","placeholder":_vm.$t('m_modifyLoginPassword.xmmyz'),"maxlength":"16","rules":[
        {
          required: true,
          validator: _vm.validator,
          message: _vm.$t('m_modifyLoginPassword.xmmyz'),
        } ]},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('van-field',{attrs:{"type":"password","name":"newPass","maxlength":"16","label":_vm.$t('m_modifyLoginPassword.qrxmm'),"placeholder":_vm.$t('m_modifyLoginPassword.xmmyz'),"rules":[
        {
          required: true,
          validator: _vm.validator,
          message: _vm.$t('m_modifyLoginPassword.xmmyz'),
        } ]},model:{value:(_vm.form.renew_password),callback:function ($$v) {_vm.$set(_vm.form, "renew_password", $$v)},expression:"form.renew_password"}}),_c('div',{staticClass:"forgetPwd toService"},[_vm._v(" "+_vm._s(_vm.$t('m_modifyLoginPassword.zhwyzfswjjmm'))+" "),_c('router-link',{staticStyle:{"color":"#9fb0ff"},attrs:{"to":"/cusService"}},[_vm._v(_vm._s(_vm.$t('m_modifyLoginPassword.lxkf')))])],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{staticClass:"btn",attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('m_modifyLoginPassword.qr')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }